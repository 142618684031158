.selectedTipButton {
  background-color: #0d6efd;
  color: #FAFDFE;
  border: 1px solid #E6E6E6;
  border-radius: 0.25rem;
}

.unselectedTipButton {
  background-color: #FAFDFE;
  border: 1px solid #E6E6E6;
  color: #0d6efd;
  border-radius: 0.25rem;
}

.zeroMargin {
  margin: 0;
}

.tipInput {
  padding: 4px;
  border: 1px solid #E6E6E6;
  width: 100%;
}

.hr {
  margin-top: 28px;
  margin-bottom: 28px;
  background-color: #aaa;
}

.submitButton {
  padding: 16px 8px;
  background-color: #28A744;
  color: #fff;
  border: none;
  margin-top: 0;
  border-radius: 0.25rem;
}

.submitButton:hover, .unselectedTipButton:hover {
  opacity: .8;
}

