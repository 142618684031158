:root {
  --primary: #0568fd;
  --dark: #000;
}
body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f8f8f8;
  height: 100%;
}

html {
  height: 100%;
}

#root {
  width: 100%;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.main-container {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.credit-card-image-container {
  display: flex;
  justify-content: space-around;
  margin-bottom: 0.5rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #e0e0e0;
}
.credit-card-image {
  display: inline;
  padding: 0.3rem;
  border: 1px solid #d4d4d4;
  border-radius: 0.25rem;
  max-height: 45px;
}

.fullsteampay-form {
  width: 500px;
  background-color: #fff;
  /* padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-top: .85rem;
  padding-bottom: .85rem; */
  /* box-shadow: 3px 3px 14px 5px #e8e8e8; */
  border-radius: 0.25rem;
  overflow: hidden;
  border: 1px solid #c7c7c7;
}
  .fullsteampay-form label {
    color: #424242;
    font-size: 0.9rem;
    font-weight: 600;
    margin-bottom: 0.15rem;
  }
  .fullsteampay-form button, .fullsteampay-form input[type="submit"] {
    display: block;
    width: 100%;
    font-size: 14px;
  }
  .fullsteampay-form input[type="submit"] {
    display: block;
    margin-top: 5px;
    width: 100%;
  }

.padded-div {
  padding: 0 2rem 1rem;
}

.payment-complete-text {
  padding: 1rem;
  text-align: center;
}

.fullSteamFilledDiv {
  margin-bottom: 5px;
  height: 35px;
  width: 100%;
}
.fullSteamFilledDivInvalid {
  box-shadow: 0 0 0 0.2rem rgba(255, 170, 0, 0.25);
}

.manual-entry-header {
  padding: 1rem 0 1rem 0;
  color: #000000;
  border-bottom: 1px solid #e0e0e0;
  margin-bottom: 0.5rem;
}
.manual-entry-header > h4 {
  text-align: center;
  margin: 0;
  font-weight: 300;
  font-size: 1.2em;
}

.total-amount-container {
  text-align: center;
  padding: 0.9rem;
}
.total-amount-container > h1 {
  margin: 0;
  font-size: 3rem;
  font-weight: 600;
}
.total-amount-container > h4 {
  margin-bottom: 0;
  font-weight: 400;
}


.giant-loading-div {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #f0f0f0;
  z-index: 1;
}
.loading-text {
  margin: 0;
  font-weight: 300;
  color: #444;
  margin-top: 1rem;
}
.giant-error-div {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #cb1a1a;
  color: #fff;
  z-index: 1;
}

.center {
  position: absolute;
  text-align: center;
  top: 50%;
  left: 50%;
  transform: translate(-50% , -50%);
  -webkit-transform: translate(-50%, -50%);
}

@keyframes loader{
  from{width:0%;}
  to{width:100%;}
}
/* #loaderBar{
  position: relative;
  bottom: 0px;
  left: 0px;
  right: 0px;
} */
#loaderBarInner{
  width: 0%;
  animation-name: loader;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}

.pay-amount {
  font-size: 2rem;
  margin-bottom: 1rem;
  font-weight: 400;
  color: var(--dark);
}

.custom-input {
  display: block;
  position: relative;
  border: 1px solid rgb(209, 213, 219);
  border-radius: 0.25rem;
  padding: 12px 8px;
  margin-bottom: 0.5rem;
  /* box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 1px 2px 0px; */
  height: 35px;
  width: 100%;
  font-size: 14px;
}

.custom-input:focus {
  outline: none;
}
/*
.custom-input:focus, .custom-input.focus {
  outline: 2px solid var(--primary);
} */

.custom-input.i-frame {
  padding: 0px 12px;
}


.position-relative {
  position: relative;
}

.cardLogos {
  text-align: center;
}
.cardLogos img {
  height: 50px;

}
.cardLogos img:nth-child(2) {
  margin-left: 15px;
  margin-right: 15px;
}
.footerLinks {
  margin-top: 10px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}
.footerLinks a {
  text-decoration: none;
  color: rgb(114, 114, 114);
  text-align: center;
}
.footerLinks a:hover {
  text-decoration: underline;
  color: #0a7bff;
}
*.unselectable {
   -moz-user-select: -moz-none;
   -khtml-user-select: none;
   -webkit-user-select: none;

   /*
     Introduced in IE 10.
     See http://ie.microsoft.com/testdrive/HTML5/msUserSelect/
   */
   -ms-user-select: none;
   user-select: none;
}
.canclick {
  cursor: pointer;
}

.processingOverlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #f0f0f0;
  z-index: 1;
}
.processingOverlayContent {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50% , -50%);
  -webkit-transform: translate(-50%, -50%);
  text-align: center;
  font-weight: bold;
  font-size: 1.5rem;
  color: #424242;
}
.processingOverlayContentText {
  font-size: 1rem;
  color: #424242;
}
